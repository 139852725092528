'use client';
import './blogCard.scss';
import Image from 'next/image';
import dayjs from 'dayjs';
import Head from 'next/head';
import { useIntl } from 'react-intl';
import MarkdownComp from './MarkDown';
import Link from 'next/link';
// import { useRouter } from 'next/router';
import { redirect } from 'next/navigation';
export default function BlogCard({ blog, isFitNess = false }) {
  const intl = useIntl();
  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    headline: blog?.attributes?.title,
    image: [blog?.attributes?.img?.data?.attributes?.url],
    datePublished: dayjs(blog?.attributes?.date).toISOString(),
    description: blog?.attributes?.descrption,
  };

  // const router = useRouter();

  const handleViewMoreClick = () => {
    const targetUrl = isFitNess
      ? `fitness-blogs/${blog?.attributes?.slug}`
      : `blogs/${blog?.attributes?.slug}`;
    redirect(targetUrl);
  };

  return (
    <Link
      href={`${
        isFitNess
          ? `fitness-blogs/${blog.attributes.slug}`
          : `blogs/${blog.attributes.slug}`
      }`}
    >
      <div className="blogCardWrapper">
        <Head>
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        </Head>
        <div className="blogImgContainer">
          <Image
            loading="lazy"
            src={blog?.attributes?.img?.data?.attributes?.url}
            alt="blog image"
            fill
            style={{ objectFit: 'cover' }}
          />
          <div className="dateContainer">
            {dayjs(blog?.attributes?.date).format('MMM DD, YYYY')}
          </div>
        </div>
        <p className="blogHeading">{blog?.attributes?.title}</p>
        {/* <p className="truncate blogSubHeading">{blog?.attributes?.descrption}</p> */}

        {/* <MarkdownComp content={blog?.attributes?.descrption}/> */}
        <button className="viewMore" onClick={handleViewMoreClick}>
          {intl.formatMessage({ id: 'viewMore' })}
        </button>
      </div>
    </Link>
  );
}
