'use client';
import { useState } from 'react';
import { Input } from '@/components/ui/input';
import React from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { useDispatch, useSelector } from 'react-redux';
import FullScreenLoader from '../FullScreenLoader/FullScreenLoader';
import { country } from '../../../../constant/country';
import { PhoneNumberUtil } from 'google-libphonenumber';
import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';
import { createPartnerLeadInZoho } from '@/action/zoho';

const Consultation = () => {
  const intl = useIntl();
  const phoneUtil = PhoneNumberUtil.getInstance();
  const treatments = useSelector((state) => state.treatments.treatments.data);
  const status = useSelector((state) => state.consultation.status);
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [selectedTreatment, setSelectedTreatment] = useState('');
  const [selectedCountry, setSelectedCountry] = useState({
    name: 'India',
    flag: '🇮🇳',
    code: 'IN',
    dial_code: '+91',
  });
  const [phoneNumber, setPhoneNumber] = useState('');

  const isPhoneValid = (phone) => {
    try {
      return phoneUtil.isValidNumber(
        phoneUtil.parseAndKeepRawInput(phone)
      );
    } catch (error) {
      return false;
    }
  };

  const handleSubmit = async () => {
    let consultationData = {
      name: name,
      country: selectedCountry.name,
      phone_number: phoneNumber,
    };

    if (selectedTreatment) {
      consultationData.treatment = selectedTreatment;
    }

    if (!isPhoneValid(selectedCountry.dial_code + phoneNumber)) {
      return toast.error(
        intl.formatMessage({ id: 'invalidPhoneNumber' })
      );
    }

    await createPartnerLeadInZoho({
      ...consultationData,
      parter_type: ['Direct Patient'],
    });
  };

  return (
    <div className="servicesWrapper">
      <div className="border border-[#EEEEEE] rounded-[20px] shadow-[0_3px_10px_rgb(0,0,0,0.1)] w-full min-h-[220px] h-full p-4">
        <p className="text-[32px] leading-14 tracking-wide text-center mt-4 text-[#006daf] font-extrabold">
          {intl.formatMessage({ id: 'getConsultation' })}
        </p>
        <div className="flex flex-row flex-wrap gap-5 mx-5 mt-5 justify-evenly">
          <div className="w-full sm:w-80">
            <Label>{intl.formatMessage({ id: 'nameLabel' })}</Label>
            <Input
              className="w-full sm:w-80"
              placeholder={intl.formatMessage({
                id: 'namePlaceholder',
              })}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="w-full sm:w-[180px]">
            <Label>
              {intl.formatMessage({ id: 'treatmentLabel' })}
            </Label>
            <Select
              onValueChange={(val) => setSelectedTreatment(val)}
            >
              <SelectTrigger className="w-full sm:w-[180px]">
                <SelectValue
                  className="placeholder:text-[#9AA3B7] opacity-10"
                  placeholder={intl.formatMessage({
                    id: 'treatmentPlaceholder',
                  })}
                />
              </SelectTrigger>
              <SelectContent>
                {treatments &&
                                    treatments.map((treatment) => {
                                      return (
                                        <SelectItem
                                          key={treatment.id}
                                          onChange={() =>
                                            setSelectedTreatment(
                                              treatment.id
                                            )
                                          }
                                          value={treatment.id}
                                        >
                                          {treatment.attributes.name}
                                        </SelectItem>
                                      );
                                    })}
              </SelectContent>
            </Select>
          </div>

          <div className="w-full sm:w-[180px]">
            <Label>
              {intl.formatMessage({ id: 'countryLabel' })}
            </Label>
            <Select
              onValueChange={(val) => setSelectedCountry(val)}
            >
              <SelectTrigger className="w-full">
                <SelectValue
                  className="placeholder:text-[#9AA3B7] opacity-10"
                  placeholder={intl.formatMessage({
                    id: 'countryPlaceholder',
                  })}
                />
              </SelectTrigger>
              <SelectContent>
                {country &&
                                    country.map((country) => {
                                      return (
                                        <SelectItem
                                          key={country.name}
                                          onChange={() =>
                                            setSelectedCountry(country)
                                          }
                                          value={country}
                                        >
                                          {country.name}
                                        </SelectItem>
                                      );
                                    })}
              </SelectContent>
            </Select>
          </div>

          <div className=" h-10 w-full sm:w-[240px]">
            <Label>
              {intl.formatMessage({ id: 'mobileLabel' })}
            </Label>
            <div className="flex w-full h-10 text-sm border rounded-md bg-background ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
              <div className="flex flex-row items-center gap-4 ml-2 bg-white">
                <span className="flex flex-col gap-2 text-sm">
                  {selectedCountry.flag}
                </span>
                {selectedCountry.dial_code}
                <Input
                  placeholder={intl.formatMessage({
                    id: 'mobilePlaceholder',
                  })}
                  className="h-8 border-none outline-none focus-visible:ring-0"
                  value={phoneNumber}
                  onChange={(e) => {
                    if (e.target.value.length > 10) return;
                    setPhoneNumber(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <Button className="mt-[24px]" onClick={handleSubmit}>
            {intl.formatMessage({ id: 'chatNowButton' })}
          </Button>
        </div>
      </div>
      <FullScreenLoader isLoading={status === 'loading'} />
    </div>
  );
};

export default Consultation;
