/**
 * Truncates a given text to a specified length, appending an ellipsis if necessary.
 *
 * @param {string} text - The text to be truncated.
 * @param {number} maxLength - The maximum length of the truncated text.
 * @param {string} [ellipsis='...'] - The ellipsis to append to the truncated text (optional, defaults to '...').
 * @returns {string} - The truncated text.
 */
export function truncateText(text, maxLength, ellipsis = '...') {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength - ellipsis.length) + ellipsis;
}
