"use client";
import "./modal.scss";
import { useState, useEffect } from "react";

export default function Modal({ showModal, closeModal, children }) {
  useEffect(() => {
    document.body.style.overflow = showModal ? "hidden" : "auto"; // Prevent scrolling when modal is open
  }, [showModal]);

  const handleCloseModal = () => {
    closeModal();
  };

  return (
    <>
      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <button className="close-modal-btn" onClick={handleCloseModal}>
              X
            </button>
            <div style={{padding: '10px'}}>
              {children}
            </div>

          </div>
        </div>
      )}
    </>
  );
}
