import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/BlogsCarousel/BlogsCarousel.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/CareScreenBanner/CareScreenBanner.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/CareScreenStatistics/CareScreenStatistics.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/Consultation/Consultation.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/InformationalVideos/InformationalVideos.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/Services/Services.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/SuccessStories/SuccessStories.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/TestimonialsCarousel/TestimonialsCarousel.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/TreatmentPackagesCarousel/TreatmentPackagesCarousel.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/AppBanner/AppBanner.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/DoctorsCarousel/DoctorsCarousel.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/HospitalsCarousel/HosptalsCarousel.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Impact/Impact.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Treatments/Treatments.js");
