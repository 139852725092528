import Image from 'next/image';
import './hospitalCard.scss';
import Link from 'next/link';
import { routeName } from '@/utils/commonfuntions';
import { useIntl } from 'react-intl';
import { useMemo, useCallback } from 'react';

export default function HospitalCardWithApi({ hospital, quoteBtn = false }) {
  const intl = useIntl();

  // Memoize route generation logic
  const hospitalSlug = useMemo(() => routeName(hospital?.attributes?.slug), [hospital?.attributes?.slug]);

  // Memoize hospital data
  const hospitalData = useMemo(() => ({
    name: hospital?.attributes?.name,
    imageUrl: hospital?.attributes?.img?.data?.attributes?.url,
    address: hospital?.attributes?.address,
    place: hospital?.place,
  }), [hospital?.attributes?.name, hospital?.attributes?.img?.data?.attributes?.url, hospital?.attributes?.address, hospital?.place]);

  // Memoize the free quote text
  const freeQuoteText = useMemo(() => intl.formatMessage({ id: 'get_free_quote' }), [intl]);

  // Memoize the component rendering
  return useMemo(() => (
    <Link
      href={{
        pathname: `/hospitals/${hospitalSlug}`,
        query: { name: hospitalData.name },
      }}
    >
      <div className="hospitalWrapper">
        <div className="hospitalContainer">
          <Image
            loading="lazy"
            src={hospitalData.imageUrl}
            alt="hospital image"
            fill
            style={{ objectFit: 'cover' }}
          />
          <div className="hospitalInfoContainer">
            <div className="hospitalInfo">
              <div className="h-[120px]">
                <p className="hospitalName">{hospitalData.name}</p>
                <p className="hospitalPlace">{hospitalData.place}</p>
                <p className="hospitalAddress">{hospitalData.address}</p>
              </div>

              {quoteBtn && (
                <div className="quoatationBtn">
                  {freeQuoteText}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Link>
  ), [hospitalSlug, hospitalData, freeQuoteText, quoteBtn]); // Dependencies for memoization
}
