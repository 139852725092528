'use client';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '@/utils/axios';

export const fetchTreatmentPackages = createAsyncThunk(
  'treatmentPackages/fetchTreatmentPackages',
  async ({ filters = {}, locale = 'en' }) => {
    let queryString = `treatment-packages?populate=img`;

    // Append locale to the query string
    queryString += `&locale=${locale}`;

    // Append filters to the query string
    if (Object.keys(filters).length > 0) {
      queryString += '&' + createFilterQueryString(filters);
    }

    const response = await axios.get(queryString);
    return response.data;
  }
);

// Function to create filter query string
function createFilterQueryString(filters) {
  let queryString = '';
  for (const key in filters) {
    const value = filters[key];
    if (typeof value === 'object') {
      // If value is an object, assume it contains comparison operators
      for (const op in value) {
        queryString += `filters[${key}][${op}]=${value[op]}&`;
      }
    } else {
      // If value is not an object, assume it's a direct value
      queryString += `filters[${key}]=${value}&`;
    }
  }
  // Remove the trailing '&' if present
  queryString = queryString.replace(/&$/, '');
  return queryString;
}

const initialState = {
  packages: [],
  status: 'idle',
  error: null,
};

const treatmentPackagesSlice = createSlice({
  name: 'treatmentPackages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTreatmentPackages.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTreatmentPackages.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.packages = action.payload;
      })
      .addCase(fetchTreatmentPackages.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default treatmentPackagesSlice.reducer;

export const selectAllTreatmentPackages = (state) =>
  state.treatmentPackage.packages;

export const selectAllTreatmentPackagesStatus = (state) =>
  state.treatmentPackage.status;
