import Link from 'next/link';
import './videoCard.scss';
import Image from 'next/image';
import { truncateText } from '@/utils/truncateText';

export default function VideoCard({ video }) {
  return (
  // <Link href={`/how-its-work/${video?.attributes?.slug}`}>
    <div className="videoCardWrapper">
      <div className="videoImgContainer">
        <Image
          src={video?.attributes?.img?.data?.attributes?.url}
          alt="video background"
          fill
          priority
          style={{ objectFit: 'cover' }}
        />
      </div>
      {video?.attributes?.title && (
        <p className="videoHeading">
          {truncateText(
            video?.attributes?.title ||
                            video?.attributes?.descrption,
            26
          )}
        </p>
      )}
    </div>
  // </Link>
  );
}
