'use client';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '@/utils/axios';

export const fetchTopDoctors = createAsyncThunk(
  'topDoctors/fetchTopDoctors',
  async ({ filters = {}, locale = 'en' } = {}) => {
    let queryString =
            'top-doctors?populate=profile,treatments,top_hospitals';

    if (locale) {
      queryString += `&locale=${locale}`;
    }

    if (Object.keys(filters).length > 0) {
      queryString +=
                '&' +
                Object.entries(filters)
                  .map(([key, value]) => {
                    if (typeof value === 'object') {
                      if (value.$eq) {
                        return `filters[${key}][$eq]=${value.$eq}`;
                      }
                      return `filters[${key}][$gt]=${value.$gt}`;
                    } else {
                      return `filters[${key}]=${value}`;
                    }
                  })
                  .join('&');
    }

    const response = await axios.get(queryString);
    return response.data;
  }
);

export const fetchDoctorsWithFilters = createAsyncThunk(
  'topDoctors/fetchDoctorsWithFilters',
  async ({ filters, locale = 'en' } = {}) => {
    let queryString = `top-doctors?populate=profile,treatments,top_hospitals`;

    if (locale) {
      queryString += `&locale=${locale}`;
    }

    queryString += `&${new URLSearchParams(filters)}`;

    const response = await axios.get(queryString);
    return response.data;
  }
);

const initialState = {
  doctors: [],
  status: '',
  error: null,
};

const topDoctorsSlice = createSlice({
  name: 'topDoctors',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTopDoctors.pending, (state) => {
        state.status = true;
      })
      .addCase(fetchTopDoctors.fulfilled, (state, action) => {
        state.status = false;
        state.doctors = action.payload;
      })
      .addCase(fetchTopDoctors.rejected, (state, action) => {
        state.status = false;
        state.error = action.error.message;
      })
      .addCase(fetchDoctorsWithFilters.pending, (state) => {
        state.status = true;
      })
      .addCase(fetchDoctorsWithFilters.fulfilled, (state, action) => {
        state.status = false;
        state.doctors = action.payload;
      })
      .addCase(fetchDoctorsWithFilters.rejected, (state, action) => {
        state.status = false;
        state.error = action.error.message;
      });
  },
});

export default topDoctorsSlice.reducer;

export const selectAllTopDoctors = (state) => state.top_doctors.doctors;
