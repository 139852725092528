import React from 'react';
import './doctorCard.scss';
import Image from 'next/image';
// import { Rating } from 'react-simple-star-rating';
import Link from 'next/link';
import Head from 'next/head';
import { useIntl } from 'react-intl';
import ForRating from '@/components/Rating/ForRating';

export default function DoctorCardWithApi({ doctor }) {
  const intl = useIntl();

  const doctorData = {
    '@context': 'https://schema.org',
    '@type': 'Person',
    name: doctor?.attributes?.name,
    jobTitle: doctor?.attributes?.position,
    alumniOf: doctor?.attributes?.institute,
    description: doctor?.attributes?.descrption,
    image: doctor?.attributes?.profile?.data?.attributes?.url,
  };

  return (
    <Link
      href={{
        pathname: `/doctors/${doctor?.attributes?.slug}`,
        query: { name: doctor?.attributes?.name },
      }}
    >
      <div className="doctorCardWrapper">
        <Head>
          <script type="application/ld+json">
            {JSON.stringify(doctorData)}
          </script>
        </Head>
        <div className="doctorImgWrapper">
          <Image
            loading="lazy"
            src={doctor?.attributes?.profile?.data?.attributes?.url}
            alt="doctor image"
            fill
            sizes="auto"
            className="object-cover"
          />
        </div>
        <div className="h-[240px]">
          <span className="flex flex-col">
            <ForRating
              initialValue={4.6}
              allowFraction
              size={14}
              readonly
              className="flex flex-row"
            />
          </span>
          <div className="doctorInfo">
            <p className="name">{doctor?.attributes?.name}</p>
            <p className="designation">
              {doctor?.attributes?.position}
            </p>
            <p className="studiedAt">
              {doctor?.attributes?.institute}
            </p>
            <p className="heading">
              {doctor?.attributes?.descrption}
            </p>
            <p className="heading">
              {intl.formatMessage({ id: 'experience' })} :{' '}
              <span className="subHeading">
                {doctor?.attributes?.experiance}
              </span>
            </p>
            <p className="heading">
              {intl.formatMessage({ id: 'surgeries' })} :{' '}
              <span className="subHeading1">
                {doctor?.attributes?.surgeries}
              </span>
            </p>
          </div>
        </div>

        <div className="priceInfo">
          <p className="priceHeading">
            {intl.formatMessage({
              id: 'treatment_price_on_request',
            })}
          </p>
          <div className="freeConsultBtn">
            {intl.formatMessage({ id: 'free_text_consult' })}
          </div>
        </div>
      </div>
    </Link>
  );
}
