'use client';
import CustomCarousel from '@/components/CustomCarousel/CustomCarousel';
import './doctorsCarousel.scss';
import Link from 'next/link';
import { fetchTopDoctors, selectAllTopDoctors } from '@/slice/topDoctorsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import DoctorCardWithApi from './DoctorCard/DoctorCardWithApi';
import { useIntl } from 'react-intl';

export default function DoctorsCarousel({ itemsPerPage, data }) {
  const dispatch = useDispatch();
  const items = useSelector(selectAllTopDoctors);
  const lang = useSelector((state) => state.locale.locale);
  const intl = useIntl();

  // Memoize topDoctors data
  const topDoctors = useMemo(() => data || items, [data, items]);

  useEffect(() => {
    if (!data && !items?.length) {
      dispatch(fetchTopDoctors({ locale: lang }));
    }
  }, [data, items, lang, dispatch]);

  return (
    <div className="doctorsCarouselWrapper">
      <div className="doctorsContainer">
        <p className="doctorsHeading">
          {intl.formatMessage({ id: 'findThe' })}{' '}
          <span className="highlight">{intl.formatMessage({ id: 'topDoctors' })}</span>
        </p>

        {topDoctors?.data?.length > 0 && (
          <CustomCarousel itemsPerPage={itemsPerPage}>
            {topDoctors.data.map((doctor, i) => (
              <DoctorCardWithApi key={doctor.id || i} doctor={doctor} />
            ))}
          </CustomCarousel>
        )}

        <Link href="/doctors" className="viewMoreBtn">
          {intl.formatMessage({ id: 'viewAllDoctors' })}
        </Link>
      </div>
    </div>
  );
}
