import { useState } from 'react';
import Link from 'next/link';
import './storyCard.scss';
import Image from 'next/image';
import { DialogDemo } from '../../DialogMemo';

export default function StoryCard({ story }) {
  const [showDialog, setShowDialog] = useState(false);

  const tags = story?.attributes?.tags?.data?.map(
    (tag) => tag.attributes.name
  );

  const handlePlayClick = (event) => {
    event.preventDefault();
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  return (
    <>
      <div className="storyCardWrapper"> {/* Make the outer div wrapper instead of Link */}
        <Link href={`/stories/${story?.attributes?.slug}`}>
          <div className="storyImgContainer">
            <Image
              loading="lazy"
              src={story?.attributes?.img?.data?.attributes?.url}
              alt="user story banner"
              fill
              style={{ objectFit: 'cover' }}
            />
          </div>

          <div className="storyUserContainer">
            <p className="storyBy">By {story?.attributes?.storyBy}</p>
            <p className="storyDate">{story?.attributes?.date}</p>
          </div>
          <div className="tagsContainer">
            {tags?.map((tag, i) => {
              if (i < 2) {
                return (
                  <div key={i} className="tagName">
                    {tag}
                  </div>
                );
              }
            })}
            <div className="tagName">+3 More</div>
          </div>
          <div className="storyContainer">
            <p className="storyHeading">{story?.attributes?.heading}</p>
            {/* <p className="storySubHeading">{story?.attributes?.subHeading}</p> */}
          </div>
        </Link>
        {/* Separate play button outside the Link */}
        <div
          className="relative -top-40 left-64 w-[50px] border-2 p-2 rounded-full cursor-pointer"
          onClick={handlePlayClick}
        >
          <svg
            id="play-button-svg"
            className="play-button-svg"
            style={{
              width: '2em',
              height: '2em',
              verticalAlign: 'middle',
              fill: 'currentColor',
              overflow: 'hidden',
            }}
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M256 832c-11.712 0-23.36-3.2-33.664-9.536A64.170667 64.170667 0 0 1 192 768V256c0-22.208 11.52-42.816 30.336-54.464a64.298667 64.298667 0 0 1 62.272-2.816l512 256a64.064 64.064 0 0 1 0 114.56l-512 256c-8.96 4.48-18.88 6.72-28.608 6.72z"
              fill=""
            />
          </svg>
        </div>
      </div>
      <DialogDemo
        showDialog={showDialog}
        closeDialog={closeDialog}
        videoUrl={story?.attributes?.videoLink}
      />
    </>
  );
}
