'use client';
import './careScreenStatistics.scss';
import Image from 'next/image';
import CountUp from 'react-countup';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
export default function CareScreenStatistics({ statistics }) {
  const intl = useIntl();

  const stats = statistics?.data?.attributes;

  return (
    <div className="statsWrapper">
      <div className="statsContainer">
        <div className="statsNumbers">
          <div className="statsInfo">
            <p className="statsCount">
              <CountUp end={stats?.Happy_Patients} duration={3} />
            </p>
            <p className="statsHeading">
              {intl.formatMessage({ id: 'patientServedHeading' })}
            </p>
          </div>
          <Image
            loading="lazy"
            src="/images/verticalLine.svg"
            alt="vertical line icon"
            width={0}
            height={0}
            style={{ width: 'auto', height: 'auto' }}
          />
          <div className="statsInfo">
            <p className="statsCount">
              <CountUp
                end={stats?.Hospital_Partners}
                duration={3}
              />
            </p>
            <p className="statsHeading">
              {intl.formatMessage({
                id: 'hospitalPartnersHeading',
              })}
            </p>
          </div>

          <Image
            loading="lazy"
            src="/images/verticalLine.svg"
            alt="vertical line icon"
            width={0}
            height={0}
            style={{ width: 'auto', height: 'auto' }}
          />

          <div className="statsInfo">
            <p className="statsCount">
              <CountUp
                end={stats?.Countries_Reached}
                duration={3}
              />
            </p>
            <p className="statsHeading">
              {intl.formatMessage({
                id: 'countriesReachedHeading',
              })}
            </p>
          </div>
        </div>
        <div className="verticalDevider"></div>
        <p className="statsTagline">
          {intl.formatMessage({ id: 'statsTagline' })}
          <br />
          <span className="price">
            {intl.formatMessage({ id: 'superiorCare' })}
          </span>
        </p>
      </div>
    </div>
  );
}
