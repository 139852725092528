import Link from 'next/link';
import './testimonialCard.scss';
import Image from 'next/image';
import Head from 'next/head';
import { useIntl } from 'react-intl';
import { truncateText } from '@/utils/truncateText';

export default function TestimonialCard({ testimonial }) {
  const intl = useIntl();
  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'Person',
    name: testimonial?.attributes?.username,
    image: testimonial?.attributes?.img?.data?.attributes?.url,
    jobTitle: 'Advisor',
  };
  return (
    <Link href={`/advisors/${testimonial?.attributes?.slug}`}>
      <div className="testmonialCardWrapper">
        <Head>
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        </Head>
        <div className="userImgContainer">
          <Image
            loading="lazy"
            src={
              testimonial?.attributes?.img?.data?.attributes?.url
            }
            alt="testimonial user image"
            width={100}
            height={100}
            className="w-[100px] h-[100px] rounded-full"
          />
        </div>
        <p className="userName">{testimonial?.attributes?.username}</p>
        <div className="treatmentInfo">
          <div className="treatmentName">
            {testimonial?.attributes?.city?.data?.attributes?.name}
          </div>
          <div className="countryName">
            {
              testimonial?.attributes?.country?.data?.attributes
                ?.name
            }
          </div>
        </div>
        <div className="flex justify-center">
          <button className="viewMoreBtn">
            {intl.formatMessage({ id: 'viewMore' })}
          </button>
        </div>
      </div>
    </Link>
  );
}
