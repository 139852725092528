'use client';
import { useEffect, useMemo } from 'react';
import CustomCarousel from '@/components/CustomCarousel/CustomCarousel';
import './hospitalsCarousel.scss';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTopHospitals, selectAllTopHospitals } from '@/slice/topHospitalsSlice';
import HospitalCardWithApi from './HospitalCard/HospitalCardWithApi';
import { useIntl } from 'react-intl';

export default function HospitalCarousel({ itemsPerPage, data }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.locale);
  const selectAllTopHospitalsData = useSelector(selectAllTopHospitals);

  // Memoize top hospitals data
  const topHospitals = useMemo(() => data || selectAllTopHospitalsData, [data, selectAllTopHospitalsData]);

  useEffect(() => {
    if (!data) {
      dispatch(fetchTopHospitals(lang));
    }
  }, [lang, data, dispatch]);

  return (
    <div className="hospitalsCarouselWrapper">
      <h1 className="p-0 m-0">
        <p className="hospitalsHeading">
          {intl.formatMessage({ id: 'exploreTopHospitals' })}{' '}
          <span className="bold">{intl.formatMessage({ id: 'topHospitals' })}</span>
        </p>
      </h1>

      {topHospitals?.data?.length > 0 && (
        <CustomCarousel itemsPerPage={itemsPerPage}>
          {topHospitals.data.map((hospital) => (
            <HospitalCardWithApi key={hospital.id} hospital={hospital} />
          ))}
        </CustomCarousel>
      )}

      <Link href="/hospitals" className="viewMoreBtn">
        {intl.formatMessage({ id: 'viewAllHospitals' })}
      </Link>
    </div>
  );
}
