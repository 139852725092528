'use client';
import CustomCarousel from '@/components/CustomCarousel/CustomCarousel';
import './testimonialsCarousel.scss';
import { testimonials } from '@/utils/data';
import TestimonialCard from './TestimonialCard/TestimonialCard';
import { useDispatch, useSelector } from 'react-redux';
import fetchAssistances, {
  fetchAssistanceData,
  selectAllAssistances,
} from '@/slice/fetchAssistances';
import { useEffect } from 'react';
import Link from 'next/link';
import { useIntl } from 'react-intl';

export default function TestimonialsCarousel({ itemsPerPage, data }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectAllAssistancesData = useSelector(selectAllAssistances);
  const assistances = data || selectAllAssistancesData;

  const lang = useSelector((state) => state.locale.locale);
  useEffect(() => {
    !data && dispatch(fetchAssistanceData({ locale: lang }));
  }, [dispatch, lang]);

  return (
    <div className="testimonialsCarouselWrapper">
      <div className="testimonialsContainer">
        <p className="testimonialsHeading">
          {intl.formatMessage({ id: 'discoverAdvisors' })}
          <span className="headingGrey">
            {' '}
            <br />
            {intl.formatMessage({ id: 'forYourHealthCareJourney' })}
          </span>
        </p>
        <CustomCarousel itemsPerPage={itemsPerPage}>
          {assistances?.data?.length > 0 &&
                        assistances?.data.map((testimonial, i) => {
                          return (
                            <TestimonialCard
                              key={i}
                              testimonial={testimonial}
                            />
                          );
                        })}
        </CustomCarousel>
        <Link href={'/advisors'}>
          <button className="viewMoreBtn">
            {intl.formatMessage({ id: 'meetAdvisors' })}
          </button>
        </Link>
      </div>
    </div>
  );
}
